import React from "react";
import styled from "styled-components";
import { colors } from "./colors";
import { Body2, Heading5, Heading6 } from "./Text";
import { toAbsoluteUrl } from "_metronic/_helpers";
import SVG from "react-inlinesvg";

//todo: 0.875 rem  yerine 28. satırda başka bir font-size kullanıldı
//todo: 1 rem olmadığı için heading5 kullanıldı
const CardConteiner = styled.div`
    background-color: #fff;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid ${colors.neutral[100]};
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
`;
const StyledCardContent = styled(Heading6)`
    color: ${colors.neutral[800]};
    letter-spacing: -0.17px;
`;
const StyledTitle = styled(Body2)`
    color: ${colors.neutral[800]};
`;
const ExtensionCard = ({ cardTitle, cardItems }) => {
    return (
        <div>
            <Heading5 className="mb-3">{cardTitle}</Heading5>
            <CardConteiner>
                {cardItems.map((cardItem, index) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="mr-4">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/course-execution.svg")} />
                            </div>
                            <div className="d-flex w-100 flex-column">
                                {cardItem.content && <StyledCardContent>{cardItem.content}</StyledCardContent>}
                                {cardItem.footer && <StyledTitle className="mt-1">{cardItem.footer}</StyledTitle>}
                                {index !== cardItems.length - 1 && <hr className="d-block my-2 w-100" />}
                            </div>
                        </div>
                    </>
                ))}
            </CardConteiner>
        </div>
    );
};

export default ExtensionCard;
